import React from 'react';
import SubMenuContainer from '../sub-menu-container/SubMenuContainer'
import InfoModal from '../../organisms/info-modal/InfoModal'


export default function RenderMenu() {

    return (
        <React.Fragment>
            <SubMenuContainer />
            <InfoModal />
        </React.Fragment> 
    )

}
