
import React, { useState } from "react";
import logo from '../../images/mealzy-logo.png'
import iphoneFeatureBg from '../../images/anny-menu-demo.png'
import annyIphoneHero from '../../images/anny-iphone-hero.png'
import mostPopularHero from '../../images/most-popular-hero.png'
import iphoneHeroBg from '../../images/iphone-hero-bg.svg'
import iphoneFeatureBackground from '../../images/iphone-feature-bg-01.svg'
import iphoneFeatureBackground2 from '../../images/iphone-feature-bg-02.svg'
import iphoneFeatureBackground3 from '../../images/iphone-feature-bg-03.svg'
import iphoneFeatureBackground4 from '../../images/iphone-feature-bg-04.svg'
import './scss/style.scss'


export default function Homepage() {
    return (
        <div class="body-wrap boxed-container overall-homepage-font">
        <header class="site-header text-light">
            <div class="container">
                <div class="site-header-inner">
                    <div class="brand header-brand">
                        <h1 class="m-0">
							<a href="#">
								{/* <!-- <img src="src/images/mealzy-logo.png" alt="Venus logo" width="70"> --> */}
							</a>
                        </h1>
                    </div>
                </div>
            </div>
        </header>

        <main>
            <section class="hero text-center text-light">
				<div class="hero-bg"></div>
				<div class="hero-particles-container">
					<canvas id="hero-particles"></canvas>
				</div>
                <div class="container-sm">
                    <div class="hero-inner">
						<div class="hero-copy">
							<img src={logo} alt="" />
	                        <h1 class="hero-title mt-0">menu made simple.</h1>
	                        <p class="hero-paragraph">A digital QR solution to make food menu easy to access. </p>
	                        <div class="hero-cta">
								<a class="button button-primary button-wide-mobile" href="/m/annys">Demo</a>
							</div>
						</div>
						<div class="mockup-container">
							<div class="mockup-bg">
								<img src={iphoneHeroBg} alt="iPhone illustration"/>
							</div>
							<img class="device-mockup" src={annyIphoneHero} alt="iPhone Hero"/>
						</div>
                    </div>
                </div>
            </section>

			<section class="features-extended section">
                <div class="features-extended-inner section-inner">
					<div class="features-extended-wrap">
						<div class="container">
							<div class="feature-extended">
								<div class="feature-extended-image">
									<div class="mockup-bg">
										<img src={iphoneFeatureBackground} alt="iPhone Feature 01 illustration"/>
									</div>
									<img class="device-mockup is-revealing" src={mostPopularHero} alt="iPhone Feature 01"/>
								</div>
								<div class="feature-extended-body is-revealing">
									<h3 class="mt-0 mb-16">Innovative UI.</h3>
									<p class="m-0">Simple to see menu. Visually curated to make ordering food a seamless experience.</p>
								</div>
							</div>
							<div class="feature-extended">
								<div class="feature-extended-image">
									<div class="mockup-bg">
										<img src={iphoneFeatureBackground2} alt="iPhone Feature 02 illustration"/>
									</div>
									<img class="device-mockup is-revealing" src={iphoneFeatureBg} alt="iPhone Feature 02" style={{visibility:"visible",opacity:"1",transform:"matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",transition:"opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, transform 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) 0s"}}/>
								</div>
								<div class="feature-extended-body is-revealing">
									<h3 class="mt-0 mb-16">Accessible Design.</h3>
									<p class="m-0">Easy to navigate between sections, making it easy to find your favourite items.</p>
								</div>
							</div>
						</div>
					</div>
                </div>
            </section>
        </main>

		<footer class="site-footer">
			<div class="footer-particles-container">
				<canvas id="footer-particles"></canvas>
			</div>
			<div class="site-footer-top">
				<section class="cta section text-light">
					<div class="container-sm">
						<div class="cta-inner section-inner">
							<div class="cta-header text-center">
								<h2 class="section-title mt-0">Get in touch</h2>
								<p class="section-paragraph">mealzyapp@gmail.com<br/>07464941745</p>
								<p class="section-paragraph"></p>	
								<div class="cta-cta">
									<a class="button button-primary button-wide-mobile" href='mailto:mealzyapp@gmail.com' >Contact</a>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<div class="site-footer-bottom">
				<div class="container">
					<div class="site-footer-inner">
						<div class="brand footer-brand">
							<a href="#">
								<img src={logo} alt="Venus logo" width="70" textAlign="center"/>
							</a>
						</div>
						<ul class="footer-links list-reset">
							<li>
								<a href="#">Contact</a>
							</li>
							<li>
								<a href="#">About us</a>
							</li>
							<li>
								<a href="#">FAQ's</a>
							</li>
							<li>
								<a href="#">Support</a>
							</li>
						</ul>
						<ul class="footer-social-links list-reset">
							<li>
								<a href="#">
									<span class="screen-reader-text">Facebook</span>
									<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
										<path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" fill="#FFF"/>
									</svg>
								</a>
							</li>
							<li>
								<a href="#">
									<span class="screen-reader-text">Twitter</span>
									<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
										<path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" fill="#FFF"/>
									</svg>
								</a>
							</li>
							<li>
								<a href="#">
									<span class="screen-reader-text">Google</span>
									<svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
										<path d="M7.9 7v2.4H12c-.2 1-1.2 3-4 3-2.4 0-4.3-2-4.3-4.4 0-2.4 2-4.4 4.3-4.4 1.4 0 2.3.6 2.8 1.1l1.9-1.8C11.5 1.7 9.9 1 8 1 4.1 1 1 4.1 1 8s3.1 7 7 7c4 0 6.7-2.8 6.7-6.8 0-.5 0-.8-.1-1.2H7.9z" fill="#FFF"/>
									</svg>
								</a>
							</li>
						</ul>
						<div class="footer-copyright">&copy; 2021 Mealzy, all rights reserved</div>
					</div>
				</div>
			</div>
        </footer>
    </div>
    );
  }
  