import React from 'react'
import './LoadingIcon.scss'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingIcon() {
    return (
        <div className='menu-icon-wrapper'>
        <CircularProgress size={80}/>
        <h1 className='loading-text'>Loading...</h1>
            
        </div>
    )
}
