import { auth, db } from "../auth/firebaseAuth";

const singUpHandler = async (signUpData, setError) => {
  try {
    const validatedSignUpData = _validatePreSubmitData(signUpData);
    if (validatedSignUpData.length > 0) {
      return setError(validatedSignUpData);
    }
    let userObject;
    try {
      userObject = await auth.createUserWithEmailAndPassword(
        signUpData.email,
        signUpData.password
      );
    } catch (err) {
      console.log(err);
      //The below occurs for when firebase throws a given error
      setError([
        err.message || "An unknown error occurred please contact help",
      ]);
      return;
    }

    //Do other relevant fire store user settings
    await _updateUserData(userObject, signUpData);
  } catch (e) {
    console.log(e);
    setError(["An unknown internal error occurred. Please contact support."]);
  }
};

//Note we don't need to a lot of validating at initial stage, since firebase does a lot of it
const _validatePreSubmitData = (data) => {
  const errors = [];
  if (!data.terms) {
    errors.push("You must agree to the terms before signing up");
  }
  if (data.password !== data.confirmPassword) {
    errors.push("The passwords provided does NOT match");
  }
  if (data.forename.length > 15 || data.surname.length > 15) {
    errors.push(
      "The forename and or surname cannot be greater than 20 characters"
    );
  }
  return errors;
};

const _updateUserData = async (userObject, userData) => {
  //Add the display name to the auth component.
  await userObject.user.updateProfile({
    displayName: userData.forename.concat(` ${userData.surname}`),
  });

  //Create an associated user element
  await db.collection("users").doc(userObject.user.uid).set({
    createdAt: Date.now(),
    email: userData.email,
    forename: userData.forename,
    surname: userData.surname,
  });
};

export default singUpHandler;
