import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Material UI
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
//Custom imports
import { invertModalState } from "../../../../state/Menu";
import OptionalSelector from "../../atoms/optional-selector";
import OrderIncrement from "../../atoms/order-increment";
import "./ModalContents.scss";

export default function ModalContents() {
  const foodData = useSelector((state) => state.menu.modalData);
  //Render out the given foods image
  const foodImage =
    foodData.imageURL &&
    typeof foodData.imageURL === "string" &&
    foodData.imageURL.length > 2 ? (
      <img alt={foodData.itemName} src={foodData.imageURL} />
    ) : null;

  return (
    <div className={"info-modal-wrapper"}>
      <div className={"info-modal-container"}>
        <div>
          <div className="info-modaL-title-container">
            <p className="info-modal-title">{foodData.itemName}</p>
            <CloseModalButton />
          </div>
          {foodImage}
          <p className="info-modal-subtitle">{foodData.description}</p>
          {/* The below is just temp, we should wrap in a container */}
          {/* {foodData.configurable ? <OptionalSelector /> : null} */}
          {/* <div className="order-increment-container">
            <OrderIncrement />
          </div> */}
        </div>
      </div>
      {/* <div className={"info-checkout-container"}>aasd</div> */}
    </div>
  );
}

function CloseModalButton() {
  const dispatch = useDispatch();
  return (
    <div className="close-modal-button-wrapper">
      <IconButton
        size="small"
        onClick={() => {
          dispatch(invertModalState());
        }}
      >
        <CloseIcon fontSize="large" style={{ color: "black" }}></CloseIcon>
      </IconButton>
    </div>
  );
}
