import React from "react";
import { useSelector, useDispatch } from "react-redux";
//Material UI
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import Modal from "@material-ui/core/Modal";
//Custom imports
import ModalContents from "../../molecules/modal-contents";
import { invertModalState } from "../../../../state/Menu";
import "./InfoModal.scss";

function calculateModalHeight(isConfigurable, hasImage, textLength) {
  //These values try do dynamically allocate the height of the elements.
  let modalHeight = 40;
  let modalPosition = 50;
  let modalHeightPosition = 60;
  if (isConfigurable || hasImage || textLength > 200) {
    modalHeight = 80;
    modalPosition = -75;
    modalHeightPosition = 20;
  }

  return { modalHeight, modalPosition, modalHeightPosition };
}

export default function InfoModal() {
  const dispatch = useDispatch();
  //Tries to dynamically allocate the modal size depending on the given foods data.
  const foodData = useSelector((state) => state.menu.modalData);
  const foodText = foodData.itemName + foodData.description;
  const modalSize = calculateModalHeight(
    foodData.configurable,
    foodData.imageURL,
    foodText.length
  );

  const useStyles = makeStyles((theme) => ({
    paper: {
      position: "absolute",
      width: "100%",
      height: `${modalSize.modalHeight}%`,
      backgroundColor: "#F2F2F2",
      padding: theme.spacing(2, 2, 0),
      borderRadius: "20px 20px 0px 0px",
    },
  }));
  const classes = useStyles();
  const modalStyle = {
    top: `${modalSize.modalHeightPosition}%`,
    left: `0%`,
    transform: `translate(-50%, ${modalSize.modalPosition}%)`,
    outline: `none`,
  };
  const isModalOpen = useSelector((state) => state.menu.modalOpen);

  return (
    <Modal
      open={isModalOpen}
      onClose={() => {
        dispatch(invertModalState());
      }}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Slide in={isModalOpen} direction={"up"}>
        <div style={modalStyle} className={classes.paper}>
          <ModalContents />
        </div>
      </Slide>
    </Modal>
  );
}
