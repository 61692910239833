import React from "react";
import { useSelector } from "react-redux";
import MenuItem from "../../atoms/menu-item/MenuItem.js";
import "./SubMenuContainer.scss";

export default function SubMenuContainer() {
  const subMenusArray = useSelector((state) => state.menu.menuData.menu);
  return subMenusArray.map((subSection, index) => {
    return (
      <div key={subSection.category+index} id={index} className="menu-sub-section-wrapper">
        <h1 className="sub-heading-title">
          {subSection.category}
        </h1>
        <div className="menu-item-wrapper">
          <MenuItem foodItems={subSection.items} />
        </div>
      </div>
    );
  });
}