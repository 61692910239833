import React from "react";
//Material UI imports
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Fab from "@material-ui/core/Fab";
import Tooltip from "@material-ui/core/Tooltip";

//Custom imports
import "./OrderIncrement.scss";
import { useSelector, useDispatch } from "react-redux";
import { decrement } from "../../../../state/Order";

const useStyles = makeStyles((theme) => ({
  fab: {
    backgroundColor: "lightgrey",
  },
}));

export default function OrderIncrement() {
  //State Management
  const counter = useSelector((state) => state.order.counter);
  const dispatch = useDispatch();
  const classes = useStyles();
  return (
    <div className="order-increment-wrapper">
      <Tooltip
        title="Remove"
        aria-label="remove"
        onClick={() => dispatch(decrement())}
      >
        <Fab className={classes.fab} size="small" disableRipple>
          <RemoveIcon />
        </Fab>
      </Tooltip>
      <div className="order-text-summary">
        <p>{counter}</p>
      </div>

      <Tooltip title="Add" aria-label="add">
        <Fab className={classes.fab} size="small" disableRipple>
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
}
