import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
// //Setting up the firebase local authentication
const firebaseConfig = {
  apiKey: "AIzaSyDb_YR0kXmilSRJ-BVmiQqN-oOpcLaMyjw",
  authDomain: "food-menu-qr.firebaseapp.com",
  databaseURL: "https://food-menu-qr.firebaseio.com",
  projectId: "food-menu-qr",
  storageBucket: "food-menu-qr.appspot.com",
  messagingSenderId: "689748361893",
  appId: "1:689748361893:web:340c91ce5fc7e70579834c",
  measurementId: "G-HZNXXNPTLG",
};

//Making sure the app is only initialized once
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); // if already initialized, use that one
}

const auth = firebase.auth(); //Authentication handler
const db = firebase.firestore(); //DB handle

export {auth,db};