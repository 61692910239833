import {useSelector, useDispatch} from 'react-redux';//Setup redux store
import { createStore,applyMiddleware,compose } from "redux";
import rootReducer from "./Reducers";
import thunk from 'redux-thunk';

//The below is needed to get the redux function working
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;