import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Header from "./organisms/header/Header";
import RenderMenu from "./organisms/render-menu";
import MenuNavigation from "./molecules/menu-navigation/MenuNavigation";
import FoodSlider from "./molecules/food-slider";
import { getMenuData } from "../../state/Menu";
import LoadingScreen from "./molecules/loading-screen";
import ErrorScreen from "./molecules/error-screen";

export default function Menu(props) {
  const dispatch = useDispatch();
  const menuID = props.match.params.menuID;
  const loading = useSelector((state) => state.menu.loading);
  const error = useSelector((state) => state.menu.error.status);

  useEffect(() => {
    dispatch(getMenuData(menuID));
  }, [menuID, dispatch]);

  const overallComponent = (
    <div className="app">
      <Header />
      <MenuNavigation/>
      <FoodSlider />
      <RenderMenu />
    </div>
  );

  return (
    <div>
      {loading ? <LoadingScreen /> : error ? <ErrorScreen /> : overallComponent}
    </div>
  );
}
