import React from 'react'
import './ErrorIcon.scss'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = {

    largeIcon: {
      width: 60,
      height: 60,
    },
  
  };

export default function ErrorIcon() {
    return (
        <div className='menu-icon-wrapper'>
        <ErrorOutlineIcon style={{ fontSize: 100 }} />
        <h1 className='center-text'>An error occurred</h1>
        <p className='center-text'>Make sure the URL is correct, if not please contact an admin.</p>
            
        </div>
    )
}
