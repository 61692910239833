import React from "react";
import "./HeaderStyle.scss";
import { useSelector } from "react-redux";
import TagNames from '../../atoms/tag-names/TagNames';
import mapIcon from './map-icon.png';

export default function Header() {
  const restaurantDetails = useSelector(
    (state) => state.menu.menuData.restaurantDetails
  );
  return (
    <div className="header-wrapper">
      <div className="header-image">
        <img src={restaurantDetails.coverImage} alt="pizza" />
      </div>
      <div className="content-container">
        <div className="content">
          <h1 className="header-title">{restaurantDetails.name}</h1>
          <p className="header-description">{restaurantDetails.description}</p>
          
        </div>
        <div className="tag-wrapper">
          <div className="tag-container">
            <TagNames />
          </div>
          <a className="location-icon" href={restaurantDetails.mapURL || ''}>
            <img src={mapIcon} alt="filter" />
          </a>
        </div>
      </div>

    </div>
  );
}
