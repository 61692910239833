import React from "react";
import ErrorIcon from "../../atoms/error-icon";
import "./ErrorScreen.scss";

export default function LoadingScreen() {
  return (
    <div className={"loading-screen-center"}>
      <ErrorIcon/>
    </div>
  );
}
