import React, { useState } from "react";
//Logic Handler
import singUpHandler from "../../service/signUpService";
//UI Imports
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUp() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [forename, setForename] = useState("");
  const [surname, setSurname] = useState("");
  const [terms, setAgreedToTerms] = useState(false);
  const [signUpFailed, setSignUpFailed] = useState([]);

  const submitHandler = (event) => {
    event.preventDefault();
    const signUpData = {
      forename,
      surname,
      email,
      password,
      confirmPassword,
      terms,
    };
    singUpHandler(signUpData, setSignUpFailed);
  };

  //TODO below needs to be implekenmeted handeld in the UI! I.e error needs to be presented
  // const allFieldsPassedIn = //CUrrently just checking if terms checked
  //   email.length > 0 &&
  //   forename.length > 0 &&
  //   surname.length > 0 &&
  //   password.length > 0 &&
  //   confirmPassword.length > 0 &&
  //   terms;

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        {signUpFailed.map((errorMessage, index) => {
          return (
            //todo This needs a better ui, rn the spacing is bad, but to do
            <Alert key={index} severity="error">
              {errorMessage}
            </Alert>
          );
        })}

        <form
          className={classes.form}
          noValidate
          onSubmit={(e) => {
            submitHandler(e);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={forename}
                onChange={(e) => setForename(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="agreeTerms" color="primary" />}
                label="By signing up you are agreeing to our terms and conditions, found here:"
                onChange={(e) => {
                  setAgreedToTerms(e.target.checked);
                }}
              />
            </Grid>
          </Grid>
          {submitButton({ submitCSS: classes.submit, terms, submitHandler })}
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="#" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}

function submitButton({ submitCSS, terms, submitHandler }) {
  //setAgreedToTerms
  if (terms) {
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={submitCSS}
        value={terms}
        onChange={(e) => {
          submitHandler(e);
        }}
      >
        Sign Up
      </Button>
    );
  } else {
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={submitCSS}
        disabled
        value={terms}
        onChange={(e) => {
          submitHandler(e);
        }}
      >
        Sign Up
      </Button>
    );
  }
}
