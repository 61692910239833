import React from "react";
import FoodImage from "../../atoms/food-images";
import { useSelector,useDispatch } from "react-redux";
import { setModalData } from "../../../../state/Menu";
import "./FoodSlider.scss";

export default function FoodSlider({ title = "Most Popular" }) {
  const dispatch = useDispatch();
  const sliderElements = useSelector((state) => state.menu.popularMenuItems);

  const sliderComponents = sliderElements.map((element, index) => {
    return (
      <div className="food-image-container" key={element.name +'image-container'+ index} onClick={() => dispatch(setModalData(element))}>
        <FoodImage
          name={element.itemName}
          price={element.price}
          imageURL={element.imageURL}
        ></FoodImage>
      </div>
    );
  });

  return (
    <div>
      <h1 className="food-slider-title">{title}</h1>
      <div className="food-slider-wrapper">{sliderComponents}</div>
    </div>
  );
}
