import React from "react";
import { useDispatch } from "react-redux";
//Custom imports
import { setModalData } from "../../../../state/Menu";
import "./MenuItem.scss";

export default function MenuItem({ foodItems }) {
  const dispatch = useDispatch();

  const foodElements = foodItems.map((foodItem, index) => {
    const foodPrice =
      typeof foodItem.price === "number" && foodItem.price >= 0
        ? `£ ${foodItem.price.toFixed(2)}`
        : foodItem.price;

    const isFoodImagePresent =
      typeof foodItem.imageURL === "string" && foodItem.imageURL.length > 2;

    const imageContainer = (
      <div className="food-image">
        <img alt={`Food ${foodItem.itemName}`} src={foodItem.imageURL} />
      </div>
    );
    return (
      <div
        className="menu-item"
        key={index + "menuItem"}
        onClick={() => dispatch(setModalData(foodItem))}
      >
        <div className="food-details">
          <h3 className="food-title">{foodItem.itemName}</h3>
          <p className="food-description">{foodItem.description}</p>
          <p className="food-price">{foodPrice}</p>
        </div>
        {isFoodImagePresent ? imageContainer : null}
      </div>
    );
  });

  return <div>{foodElements}</div>;
}
