import React from "react";
import "./FoodImage.scss";

export default function FoodImage({ imageURL, name = "", price = "" }) {
  //Validate price:
  price =
    typeof price === "number" && price >= 0 ? `£ ${price.toFixed(2)}` : price;
  //Note if we might want to check if the image url is valid!

  const backgroundImage = `linear-gradient(358.79deg, #261C1C -6.98%, rgba(0, 0, 0, 0) 54.88%),url("${imageURL}")`;
  return (
    <div
      className="food-image-wrapper"
      style={{ backgroundImage: backgroundImage }}
    >
      <div className="text-position">
        <div className="letter-spacing">
          <p className="title-container title">{name}</p>
          <p className="price-container price">{price}</p>
        </div>
      </div>
    </div>
  );
}
