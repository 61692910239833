//Get menu data function
import { getMenuDetails } from "../service/menuService";
// All menu changes
const SET_MENU_DATA = "app/state/menu/setMenuData";
const SWITCH_LOADING = "app/state/menu/setLoading";
const SET_ERROR = "app/state/menu/setError";
// All modal state changes
const INVERT_MODAL_STATE = "app/state/menu/invertModalState";
const SET_MODAL_DATA = "app/state/menu/setModalData";

const menuInitialState = {
  menuData: {
    menu: [],
    popular: [],
    restaurantDetails: {
      coverImage: "",
      name: "",
      description: "",
      tagNames:[]
    },
  },
  loading: false,
  popularMenuItems: [],
  modalOpen: false,
  modalData: {},
  error: {
    status: false,
    message: "",
  },
};

export default function orderReducer(state = menuInitialState, action) {
  switch (action.type) {
    case SET_MENU_DATA:
      return {
        ...state,
        popularMenuItems: action.popularElements,
        menuData: action.menuData,
        loading: !state.loading,
      };
    case INVERT_MODAL_STATE:
      return { ...state, modalOpen: !state.modalOpen };
    case SET_MODAL_DATA:
      return { ...state,modalData: action.modalData , modalOpen:action.modalOpen};
    case SWITCH_LOADING:
      return { ...state, loading: !state.loading };
    case SET_ERROR:
      return { ...state, error: action.errorState };
    default:
      return state;
  }
}
/******************** Reducer Functions ****************************/

export const invertError = (errMessage = "") => {
  const errorState = {
    message: errMessage.message,
    status: errMessage !== "",
  };
  return {
    type: SET_ERROR,
    errorState,
  };
};

export const invertLoadingState = () => {
  return {
    type: SWITCH_LOADING,
  };
};

export const invertModalState = () => {
  return {
    type: INVERT_MODAL_STATE,
  };
};

export const setModalData = (currentElement) => {
  return {
    type: SET_MODAL_DATA,
    modalData: currentElement,
    modalOpen:true,
  };
};

const updateMenuData = (menuData) => {
  const popularElements = _getPopularDataElements(menuData);
  return {
    type: SET_MENU_DATA,
    menuData,
    popularElements,
  };
};

/******************** ASYNC Functions ****************************/
export const getMenuData = (menuID) => {
  return async (dispatch) => {
    dispatch(invertLoadingState());
    try {
      const menuData = await getMenuDetails(menuID);
      const doc = menuData.data();
      if (!doc) {
        throw new Error("An incorrect menu id was passed in");
      }
      dispatch(updateMenuData(doc));
    } catch (e) {
      console.log("Some error occurred", e);
      dispatch(invertError(e));
      dispatch(invertLoadingState());
    }
  };
};

/****************** HELPER FUNCTIONS *****************/
//Helper function to get the relevant menu data
const _getPopularDataElements = (menuData) => {
  const allPopularElementIDS = menuData.popular;
  const categoryElements = menuData.menu.map((category) => category.items);
  //Looping through the values to look for the current updated set of elements
  const popularElements = [];
  for (let x = 0; x < categoryElements.length; x++) {
    for (let y = 0; y < categoryElements[x].length; y++) {
      if (
        categoryElements[x][y].id &&
        allPopularElementIDS.includes(categoryElements[x][y].id)
      ) {
        popularElements.push(categoryElements[x][y]);
      }
    }
  }
  return popularElements;
};
