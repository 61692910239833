import React from "react";
import NavigationButton from "../../atoms/navigation-button/NavigationButton.js";
import "./ButtonStyle.scss";
import { useSelector } from "react-redux";
import filter from './filter.svg';

export default function MenuNavigation() {
  const categories = useSelector((state) => state.menu.menuData.menu);
  return (
    <div className="nav-button-container" >
      {/* <a className="filter" href="www.google.com">
        <img src={filter} alt="filter" />
      </a> */}
      <NavigationButton categoryData={categories} />
    </div>
  );
}
