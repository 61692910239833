//Import relevant reducers
import menuReducer from "./Menu.js";
import orderReducer from "./Order.js";

//Combine the reducers
import { combineReducers } from "redux";

const rootReducer = combineReducers({
  menu: menuReducer,
  order: orderReducer,
});

export default rootReducer;