import React, { Component } from "react";
import "../../molecules/menu-navigation/ButtonStyle.scss";
import { Link } from "react-scroll";
import { useSelector } from "react-redux";
import "./NavigationButton.scss";


let categories = []


export default class NavigationButton extends Component {
    

    constructor(props) {
      super(props);
      categories = props.categoryData
      categories.forEach(category => {
        this[category.id -1] = React.createRef();
      });
    }
  
    scrollToCategory = id => {
      this[id].current.scrollIntoView({ inline: "center" });
    };

  render() {
    console.log(categories);
    return categories.map((subHeadingItem, index) => {
      return (
        <li
        key={subHeadingItem.id-1 }
        style={{
          display: "inline-block"
        }}
        ref={this[subHeadingItem.id-1]}
        >
  
        <Link
          activeClass="active"
          spy={true}
          key={subHeadingItem.category + (subHeadingItem.id-1)}
          to={(subHeadingItem.id-1).toString()}
          smooth={true}
          duration={1000}
          offset={-58}
          className="button-item"
          onSetActive={() => this.scrollToCategory((subHeadingItem.id-1))}>
          {subHeadingItem.category}
        </Link>
        </li>
      );
    });
  }
}