import React from "react";
import LoadingIcon from "../../atoms/loading-icon";
import "./LoadingScreen.scss";

export default function LoadingScreen() {
  return (
    <div className={"loading-screen-center"}>
      <LoadingIcon/>
    </div>
  );
}
