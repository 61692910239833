const CREATE_NEW_ORDER = "app/state/order/updateCurrentOrder";
const INCREMENT = "app/state/order/increment";
const DECREMENT = "app/state/order/decrement";

const initialState = {
  currentActiveOrder: {
    currentOrder: {
      orderDetails: {},
      orderOptions: [],
    },
    multiple: 0,
    currentOrderPrice: 0,
  },
  totalOrder: [],
  totalPrice: 0,
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_NEW_ORDER:
      return { ...state, currentActiveOrder: action.newOrder };
    case DECREMENT:
      return { ...state, counter: state.counter - 1 };
    default:
      return state;
  }
}

export const startNewOrder = (currentModalOrderDetails) => {
  const newCurrentDataTemplate = {
    currentOrder: {
      orderDetails: currentModalOrderDetails,
      orderOptions: [],
    },
    multiple: 0,
    currentOrderPrice: 0,
  };
  return {
    type: CREATE_NEW_ORDER,
    newOrder: newCurrentDataTemplate,
  };
};

export const decrement = () => ({
  type: DECREMENT,
});
