import React from "react";
import { Switch, Route } from "react-router-dom";
//Supported components
import SignUp from "./components/SignUp";
import Login from "./components/Login";
import Menu from "./components/Menu";
import Homepage from './components/Homepage'

function App() {
  return (
    <Switch>
      <Route exact path={"/"} component={Homepage} />
     <Route path={"/signup"} component={SignUp} />
      <Route path={"/login"} component={Login} />
      <Route path={"/m/:menuID"} component={Menu}/>
    </Switch>
  );
}

export default App;
