import React from "react";
import "./TagNames.scss";
import { useSelector } from "react-redux";


export default function TagNames() {
    const tagNames = useSelector((state) => state.menu.menuData.restaurantDetails.tagNames);
    return tagNames.map((tagName, index) => {
      return (
        <div className="tag-item" key={"tagName" + index}>
          {tagName}
        </div>
      );
    });
}